import { CommonResponse, Paged } from '@/models/common/response';
import { SdTask, SdTaskResult } from '@/models/sd/SdFile';
import request from '@/utils/request';

export async function listWorksPaged(type, pageNo = 1, pageSize = 20): Promise<CommonResponse<SdTaskResult[]>> {
  const response = await request
    .get<string, CommonResponse<SdTaskResult[]>>(
      `/api/v1/sd/task-results/paged?pageNo=${pageNo}&pageSize=${pageSize}${type ? `&type=${type}` : ''}`
    );
  return response;
}

interface IListTasksPagedParams {
  type: string;
  unfinished?: string; // N|Y
  pageNo?: number;
  pageSize?: number;
}

export async function listTasksPaged(params: IListTasksPagedParams): Promise<CommonResponse<Paged<SdTask>>> {
  const { type, unfinished, pageNo, pageSize } = params;
  let url = `/api/v1/sd/tasks/paged?type=${type}&pageNo=${pageNo}&pageSize=${pageSize}`;
  if (unfinished !== undefined) {
    url += `&unfinished=${unfinished}`;
  }
  const response = await request.get<string, CommonResponse<Paged<SdTask>>>(url);
  return response;
}

export async function listWorks({ type, unfinished }): Promise<CommonResponse<SdTaskResult[]|SdTask[]>> {
  let url = `/api/v1/sd/tasks?type=${type}`;
  if (unfinished !== undefined) {
    url += `&unfinished=${!!unfinished}`;
  }
  const response = await request.get<string, CommonResponse<SdTaskResult[]|SdTask[]>>(url);
  return response;
}