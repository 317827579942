import React, { useRef, useEffect } from 'react';

function removeHtmlBodyStyles(htmlString) {
  // 创建一个DOM解析器实例
  const parser = new DOMParser();
  // 解析HTML字符串为DOM文档
  const doc = parser.parseFromString(htmlString, "text/html");

  // 获取所有<style>标签
  const styles = Array.from(doc.getElementsByTagName("style"));

  // 遍历每个<style>标签
  styles.forEach(styleEl => {
    // 获取样式文本内容
    let cssText = styleEl.textContent;
    // 移除 body 和 html 的样式规则
    // cssText = cssText.replace(/(@media.*?\{[\s\S]*\}|[^@][\s\S]*)(body|html)\s*\{[\s\S]*?\}/gim, '');
    // 更新<style>标签的内容
    styleEl.textContent = cssText;
  });

  // 序列化修改后的DOM为新的HTML字符串
  return new XMLSerializer().serializeToString(doc);
}

const getHtmlContent = (data) => {
  let htmlContent = data;//removeHtmlBodyStyles(data)
  if (htmlContent && htmlContent.includes('```html')) {
    htmlContent = htmlContent.replace(/```html/, '```');
    const start = htmlContent.indexOf('```');
    const end = htmlContent.lastIndexOf('```');
    htmlContent = htmlContent.substring(start + 3, end);
  }
  return htmlContent;
};

// 使用一个独立的样式环境

const HtmlOutputRender = ({ data, isMobilePortrait }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const shadow = containerRef.current.shadowRoot || containerRef.current.attachShadow({ mode: 'open' });

      // 创建样式元素
      const style = document.createElement('style');
      
      // 创建内容容器
      const container = document.createElement('div');
      container.innerHTML = getHtmlContent(data);

      // 清空 Shadow DOM
      shadow.innerHTML = '';

      // 将样式和内容添加到 Shadow DOM
      shadow.appendChild(style);
      shadow.appendChild(container);

      // 加载 Tailwind CSS
      fetch('https://ablula.oss-accelerate.aliyuncs.com/sd-online/assets/tailwind.js')
        .then(response => response.text())
        .then(scriptContent => {
          // 创建并执行 Tailwind 脚本
          const tailwindScript = document.createElement('script');
          tailwindScript.textContent = scriptContent;
          shadow.appendChild(tailwindScript);

          // 配置 Tailwind
          const tailwindConfig = document.createElement('script');
          tailwindConfig.textContent = `
            tailwind.config = {
              important: true,
              content: [
                '${getHtmlContent(data).replace(/'/g, "\\'")}',
              ],
            }
          `;
          shadow.appendChild(tailwindConfig);

          // 触发 Tailwind 编译
          const observer = new MutationObserver(() => {
            if (shadow.querySelector('[data-tailwind-injected]')) {
              style.textContent = shadow.querySelector('[data-tailwind-injected]').textContent;
              observer.disconnect();
            }
          });
          observer.observe(shadow, { childList: true, subtree: true });

          // 触发 Tailwind 重新编译
          const event = new Event('DOMContentLoaded');
          shadow.dispatchEvent(event);
        })
        .catch(error => console.error('Error loading Tailwind:', error));
    }
  }, [data, isMobilePortrait]);

  return <div ref={containerRef}></div>;
}

export const TextOutputRender = ({ data }) => {
  return (
    <div className='flex p-4 justify-center items-center'>
      {data}
    </div>
  );
}

export default HtmlOutputRender;
