import { onDownload } from "@/utils";
import { DownloadOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Spin, Button, Modal, message } from "antd";

import styles from './index.module.scss';
import { useEffect, useRef, useState, useMemo } from "react";
import { SdTask, SdTaskResult } from "@/models/sd/SdFile";
import { listTasksPaged } from "@/services/Works";
import { TextOutputRender } from "./HtmlOutputRender";
import moment from 'moment';
import { motion, AnimatePresence } from "framer-motion";

import WorksDetail from "../WorksDetail";
import { getSdTask } from "@/services/SdFile";

moment.locale('zh-cn');

export interface IResultPreviewProps {
  item?: SdTaskResult;
  onSelect?: (works?: SdTaskResult) => void;
  onFullscreen?: (works?: SdTaskResult) => void;
  selected?: boolean;
}

const ResultPreview = (props: IResultPreviewProps) => {
  const { item, selected, onSelect, onFullscreen } = props;
  const [ isSelected, setIsSelected ] = useState(selected);
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);
  if (!item) {
    return null;
  }
  const handleDownload = (e) => {
    e.stopPropagation();
    if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
      messageApi.error('文本输出，不支持下载');
    } else if (item.contentType === 'TEXT') {
      messageApi.error('文本输出，不支持下载');
    } else {
      onDownload(
        item?.publicId, 
        `https://ablula.oss-accelerate.aliyuncs.com/${item?.results}`,
        item?.results?.endsWith?.('.webm') || item?.results?.endsWith?.('.mp4') ? 'video' : 'image'
      );
    }
  }
  let content;
  const imagePath = item?.results;
  const audioUrl = item?.audioUrl;
  const videoUrl = item?.videoUrl;
  const styleSuffix = (item?.type === 'sora_001' || imagePath?.endsWith('gif')) ? '' : '!wm';
  if (videoUrl) {
    content = <video style={{objectFit: 'contain', maxHeight: '100%'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${videoUrl}`} controls loop />;
  } else if (audioUrl) {
    content = <audio style={{objectFit: 'contain'}} src = {`https://ablula.oss-accelerate.aliyuncs.com/${audioUrl}`} controls loop ></audio>;
  } else if (imagePath) {
    if (item.results?.indexOf('```') !== -1 || item.contentType === 'HTML') {
      try {
        content = (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <iframe height="100%" width="100%" src={`/static/llm-card/${item.publicId}`} />
            <div 
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'transparent' }}
              onClick={() => onSelect?.(item)}
            />
          </div>
        );
      } catch (e) {
        console.error('parse origin results failed: ', e);
      }
    } else if (item.contentType === 'TEXT') {
      content = <TextOutputRender data={item.results} isMobilePortrait={true} />;
    } else if (/[\u3400-\u9FBF]+/.test(imagePath)) {
      content = <span>{imagePath}</span>;
    } else if (imagePath.endsWith('.webm') || imagePath.endsWith('.mp4')) {
      content = <video style={{objectFit: 'contain'}} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}`} controls loop />;
    } else {
      content = <img style={{
        objectFit: 'contain',
        height: '100%',
      }} src={`https://ablula.oss-accelerate.aliyuncs.com/${imagePath}${styleSuffix}`} />;
    }
  }

  return (
    <div className={`${isSelected ? styles.selectedPreviewItem : styles.previewItem} relative group`} onClick={() => { onSelect?.(item) }}>
      {contextHolder}
      {content}
      <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex space-x-2">
        <Button
          type="text"
          className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
          onClick={(e) => {
            e.stopPropagation();
            onFullscreen?.(item);
          }}
        >
          <FullscreenOutlined />
        </Button>
        <Button
          type="text"
          className="bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 shadow-md backdrop-blur-sm"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(e);
          }}
        >
          <DownloadOutlined />
        </Button>
      </div>
    </div>
  )
}

export interface ITaskLoadingViewProps {
  item?: SdTask;
  selected?: boolean;
  onSelect?: (item?: SdTaskResult) => void;
}

const TaskLoadingView = (props: ITaskLoadingViewProps) => {
  const { item, selected, onSelect } = props;
  const [currentStatus, setCurrentStatus] = useState(item?.stage);
  const [currentProgress, setCurrentProgress] = useState(item?.progress);

  useEffect(() => {
    setCurrentStatus(item?.stage);
    setCurrentProgress(item?.progress);
  }, [item]);

  if (!item) {
    return null;
  }

  return (
    <div 
      onClick={() => {
        const taskResult = {
          params: item.params,
          taskId: item.publicId,
        }
        onSelect?.(taskResult);
      }}
      className={`bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-md p-2 xs:p-3 sm:p-4 flex flex-col items-center justify-center h-full overflow-hidden relative ${selected ? 'border-2 border-blue-500' : ''}`}
    >
      {currentStatus === 'FAILED' ? (
        <div className="text-red-500 font-bold text-xs xs:text-sm sm:text-base">任务失败</div>
      ) : (
        <>
          <div className="relative w-16 h-16 xs:w-20 xs:h-20 sm:w-24 sm:h-24 mb-2 xs:mb-3 sm:mb-4">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-gray-200"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="44"
                cx="50"
                cy="50"
              />
              <circle
                className="text-blue-500"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="44"
                cx="50"
                cy="50"
                strokeDasharray="276.46015351590177"
                strokeDashoffset={276.46015351590177 - (276.46015351590177 * (currentProgress || 0)) / 100}
                style={{
                  transition: 'stroke-dashoffset 0.5s ease-out',
                }}
              />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-blue-600 font-bold text-xs xs:text-sm sm:text-base">
                {Math.round(currentProgress || 0)}%
              </div>
            </div>
          </div>
          <div className="text-gray-700 font-semibold text-xxs xs:text-xs sm:text-sm mb-1 xs:mb-2 sm:mb-3">
            {+(currentProgress || 0) <= 0 ? "准备中..." : "处理中..."}
          </div>
          <div className="w-full px-1 xs:px-2 sm:px-3">
            <div className="bg-gray-200 h-1 xs:h-1.5 sm:h-2 rounded-full overflow-hidden">
              <div 
                className="bg-blue-500 h-full rounded-full transition-all duration-300 ease-out"
                style={{ width: `${Math.min(Math.max(+(currentProgress || 0), 1), 99)}%` }}
              ></div>
            </div>
          </div>
        </>
      )}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-purple-100 opacity-0 animate-pulse-strong"></div>
    </div>
  );
};

export interface IOutputRenderProps {
  code?: string;
  workflow?: any;
  onSelect?: (works?: SdTaskResult) => void;
  latestGenerateTimestamp?: number;
  isMobilePortrait?: boolean;
  isNoHead?: boolean;
}

interface IWorksHistoryProps {
  code?: string;
  workflow?: any;
  isMobilePortrait?: boolean;
  isNoHead?: boolean;
  onSelect?: (works?: SdTaskResult) => void;
  latestGenerateTimestamp?: number;
}

interface IWorksHistoryItemProps {
  workflow?: any;
  item: SdTask;
  onSelect?: (works?: SdTaskResult) => void;
  onFullscreen?: (works?: SdTaskResult) => void;
  selected?: boolean;
}

const WorksHistoryItem = (props: IWorksHistoryItemProps) => {
  const { item, onSelect, onFullscreen, selected } = props;
  const { results, stage } = item;
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSelect = (result: SdTaskResult) => {
    onSelect?.(result);
  }

  const firstResult = results?.[0];
  const hasMultipleResults = results?.length > 1;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const contentView = item?.status === 'FAILED' 
    ? <div
        className={`${selected ? styles.selectedPreviewItem : styles.previewItem} relative group`} onClick={() => { 
          const taskResult = {
            params: item.params,
            taskId: item.publicId,
          }
          onSelect?.(taskResult) 
        }}> 
        生成失败 
      </div>
    : <>
      {
        item?.stage === "FINISHED" ? <ResultPreview
          item={firstResult} 
          selected={selected}
          onSelect={handleSelect} 
          onFullscreen={() => onFullscreen?.(firstResult)} 
        /> : <TaskLoadingView item={item} onSelect={handleSelect} selected={selected} />
      }
      {hasMultipleResults && (
        <button
          className="absolute bottom-3 right-3 bg-white bg-opacity-90 text-gray-800 text-sm font-medium px-3 py-1 rounded-full shadow-md hover:bg-opacity-100 transition-all duration-200 z-10 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'Close' : `+${results.length - 1}`}
        </button>
      )}
    </>

  return (
    <div 
      ref={containerRef}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 overflow-visible group relative"
    >
      <div className="relative aspect-square">
        {contentView}
      </div>
      
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-lg overflow-hidden z-20 mt-2"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            <div className="p-4 overflow-y-auto">
              <div className="grid grid-cols-2 gap-4">
                {results?.slice(1).map?.((result, index) => (
                  <ResultPreview 
                    key={index} 
                    item={result} 
                    onSelect={() => onSelect?.(result)} 
                    onFullscreen={() => onFullscreen?.(result)} 
                  />
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const WorksHistory = (props: IWorksHistoryProps) => {
  const { code, workflow, isMobilePortrait, isNoHead, onSelect, latestGenerateTimestamp } = props;
  const [ tasks, setTasks ] = useState<SdTask[]>([]);
  const [ pageNo, setPageNo ] = useState(1);
  const [ hasMore, setHasMore ] = useState(true);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ fullscreenWorks, setFullscreenWorks ] = useState<SdTaskResult>();
  const [ worksList, setWorksList ] = useState<SdTaskResult[]>([]);
  const [ selectedResult, setSelectedResult ] = useState<SdTaskResult>();
  const [ unfinishedTaskList, setUnfinishedTaskList ] = useState<SdTask[]>([]);
  const [ refreshFlag, setRefreshFlag ] = useState(0);
  const loadingSet = useRef(new Set<string>());

  const updateTaskByPublicId = (task) => {
    if (!task?.publicId || !tasks?.length) {
      return;
    }
    setTasks(prev => prev.map(item => item.publicId === task.publicId ? task : item));
  }

  useEffect(() => {
    setRefreshFlag(prev => prev + 1);
  }, [latestGenerateTimestamp]);

  useEffect(() => {
    async function init() {
      if (!code) {
        setTasks([]);
        return;
      }
      const taskListResp = await listTasksPaged({ type: code, pageNo: 1, pageSize: 20 });
      const taskList = taskListResp?.data?.list;
      setTasks(taskList);
      if (taskList?.[0]?.results?.[0]) {
        handleSelect(taskList?.[0]?.results?.[0]);
      } else if (taskList?.[0]?.stage !== 'FINISHED') {
        const taskResult = {  
          params: taskList?.[0]?.params,
          taskId: taskList?.[0]?.publicId,
        }
        handleSelect(taskResult);
      }
      setHasMore(taskList?.length === 20);
    }
    init();
  }, [code, refreshFlag]);

  useEffect(() => {
    if (!code) {
      return;
    }
    const loadMore = async () => {
      const taskListResp = await listTasksPaged({ type: code, pageNo: pageNo, pageSize: 20 });
      if (!taskListResp?.data?.list?.length) {
        return;
      }
      setTasks(prev => [...prev, ...taskListResp?.data?.list]);
      setHasMore(taskListResp?.data?.list?.length === 20);
    }
    loadMore();
  }, [pageNo, code])

  useEffect(() => {
    const worksList: SdTaskResult[] = [];
    tasks?.forEach?.(task => {
      if (task.results && Array.isArray(task.results)) {
        worksList.push(...(task?.results || []));
      }
      if (task.stage !== 'FINISHED' && !unfinishedTaskList?.find(item => item.publicId === task.publicId)) {
        setUnfinishedTaskList(prev => [...prev, task]);
      }
    });
    setWorksList(worksList);
  }, [tasks]);

  useEffect(() => {
    if (!loadingSet?.current) {
      return;
    }
    if (!unfinishedTaskList?.length) {
      return;
    }
    unfinishedTaskList.forEach(item => {
      if (!item.publicId) {
        return;
      }
      if (loadingSet.current.has(item.publicId)) {
        return;
      }
      loadingSet.current.add(item.publicId);
    })
    const fetchTask = async () => {
      loadingSet.current.forEach(async id => {
        const taskResp = await getSdTask(id);
        if (taskResp?.data?.stage === 'FINISHED') {
          loadingSet.current.delete(id);
          setRefreshFlag(prev => prev + 1);
        } else {
          updateTaskByPublicId(taskResp?.data);
        }
      })
    }
    const handle = setInterval(() => {
      fetchTask();
    }, 3000);
    return () => clearInterval(handle);
  }, [unfinishedTaskList]);

  const handleSelect = (result: SdTaskResult) => {
    setSelectedResult(result);
    onSelect?.(result);
  }

  const groupedTasks = useMemo(() => {
    const groups = {};
    tasks?.forEach?.(task => {
      const date = moment(task.createdAt);
      const key = date.format('YYYY-MM-DD');
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(task);
    });
    return Object.entries(groups).sort(([a], [b]) => moment(b).diff(moment(a)));
  }, [tasks]);

  const renderDateHeader = (dateString: string) => {
    const date = moment(dateString);
    let displayDate;
    if (date.isSame(moment(), 'day')) {
      displayDate = '今天';
    } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
      displayDate = '昨天';
    } else {
      displayDate = date.format('M月D日');
    }
    
    const dayOfWeek = date.format('dddd');
  
    return (
      <div className="sticky top-0 z-10 py-4 mb-6 bg-white/80 backdrop-blur-md">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-baseline">
            <span className="text-2xl font-semibold text-gray-900">{displayDate}</span>
            <span className="ml-2 text-sm font-medium text-gray-500">{dayOfWeek}</span>
          </div>
          <div className="text-sm font-medium text-gray-400">
            {date.format('YYYY年')}
          </div>
        </div>
        <div className="mt-2 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent"></div>
      </div>
    );
  };

  return (
    <div className="h-[calc(100vh-100px)] flex flex-auto flex-col gap-6 overflow-y-auto pr-4">
      {groupedTasks.map(([date, dateTasks]) => (
        <div key={date} className="mb-8">
          {renderDateHeader(date)}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {dateTasks?.map?.(item => (
              <WorksHistoryItem
                key={item.publicId}
                workflow={workflow}
                item={item}
                onSelect={handleSelect}
                onFullscreen={setFullscreenWorks}
                selected={selectedResult?.taskId === item.publicId}
              />
            ))}
          </div>
        </div>
      ))}
      {isLoading && <div className="flex justify-center"><Spin /></div>}
      {!isLoading && hasMore && (
        <Button onClick={() => setPageNo(prev => prev + 1)} className="self-center">
          Load More
        </Button>
      )}
      <Modal
        className={styles.worksDetailModal}
        open={!!fullscreenWorks}
        footer={null}
        onCancel={() => {
          setFullscreenWorks(undefined);
        }}
        closeIcon={null}
      >
        <WorksDetail 
          onClose={() => {
            setFullscreenWorks(undefined);
          }}
          works={fullscreenWorks} 
          worksList={worksList}
        />
      </Modal>
    </div>
  );
};


const NormalOutputRender = (props: IOutputRenderProps) => {
  const { code, onSelect, isMobilePortrait, latestGenerateTimestamp, workflow } = props;
  
  return <div className={isMobilePortrait ? '' : 'py-4 gap-4 overflow-y-auto flex flex-row'}>
    <div className="mx-2 border-r border-dashed border-gray-300" style={{width: '0.5px'}}></div>
    <WorksHistory latestGenerateTimestamp={latestGenerateTimestamp} workflow={workflow} code={code} onSelect={onSelect} />
  </div>
}

export { NormalOutputRender };