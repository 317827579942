import AvatarUploader from "@/components/AvatarUploader";
import { Input, Select, Form, Button, message, Switch, FormInstance } from "antd";
import { WorkflowItem, FieldData } from "@/constants";
import { ComfyNode, NodeDef, NodeDefs, OutputNode, ParamNode } from "@/models/sd/comfy";
import Cascader, { DefaultOptionType } from "antd/es/cascader";
import { useState, useCallback, useEffect } from "react";
import { api } from "@/scripts/api.js";
import { DEFAULT_PARAM_NODE_TYPE, DEFAULT_CHECKPOINT_NODE_TYPE, DEFAULT_LORA_NODE_TYPE, DEFAULT_CONTENT_NODE_TYPE, DEFAULT_OUTPUT_NODE_TYPE } from "@/constants/ComfyUI";

import style from './index.module.scss';
import { DeleteOutlined } from "@ant-design/icons";

const ContentTypeMap = {
  'text': 'TEXT',
  'STRING': 'TEXT',
  'image': 'IMAGE',
  'html': 'HTML',
  'boolean': 'BOOLEAN',
  'INT': 'NUMBER',
  'FLOAT': 'NUMBER',
  'NUMBER': 'NUMBER',
}

const outputTypeOptions = [
  {
    label: 'Image',
    value: 'IMAGE',
  },
  {
    label: 'Text',
    value: 'TEXT',
  },
  {
    label: 'Html',
    value: 'HTML',
  },
  {
    label: 'Boolean',
    value: 'BOOLEAN',
  },
  {
    label: 'Number',
    value: 'NUMBER',
  },
]

const parseParamDef = (paramNode: ParamNode, paramDef) => {
  if (!paramDef || !Array.isArray(paramDef) || !paramDef.length) {
    console.error('paramDef is required, ', paramNode);
    return;
  }
  // Image 特殊处理
  if (paramNode.paramKey &&['images', 'image', 'base64_image'].includes(paramNode.paramKey)) {
    paramNode.paramType = 'Image';
    return;
  }
  if (paramDef.length === 1) {
    const options = paramDef[0];
    if (!options) {
      console.error('options is required, ', paramNode);
      return;
    }
    if (Array.isArray(options)) {
      paramNode.paramType = 'Array';
      paramNode.options = options;
    }
  } else {
    const paramType = paramDef[0];
    if (typeof paramType === 'string') {
      paramNode.paramType = ContentTypeMap[paramType] || paramType;
    } else if (Array.isArray(paramType)) {
      paramNode.paramType = 'Array';
      paramNode.options = paramType;
    }
    const paramConfig = paramDef[1];
    if (typeof paramConfig === 'object') {
      paramNode.paramConfig = paramConfig;
    }
  }
}

export interface IAppCreatorFormProps {
  workflow?: WorkflowItem;
  formRef?: FormInstance;
  onChange?: (workflow: WorkflowItem|null) => void;
}

export interface IParamItemCompProps {
  options?: DefaultOptionType[];
  onDelete?: (index: number) => void;
  paramNode?: ParamNode;
  onChange?: (paramNode: ParamNode) => void;
}

const ParamItemComp: React.FC<IParamItemCompProps> = (props: IParamItemCompProps, key) => {

  const { options, paramNode, onDelete, onChange } = props;

  return <div key={key} className={style.paramItem}>
    <Cascader 
      style={{ minWidth: 300, height: 40 }} 
      options={options} 
      placeholder="选择参数"
      defaultValue={[paramNode?.nodeId, paramNode?.paramKey]}
      onChange={(value) => {
        const [nodeId, paramKey] = value || [];
        onChange?.({
          ...paramNode,
          nodeId: nodeId as number,
          paramKey: paramKey as string,
        });
      }}
    />
    <Input 
      style={{ height: 40 }}
      placeholder="参数别名" 
      disabled={!!paramNode?.key} 
      value={paramNode?.key} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          key: e.target.value,
        });
      }}
    />
    {
      paramNode?.paramKey &&['text', 'prompt', 'string'].includes(paramNode?.paramKey) &&
      <div className="flex items-center justify-between w-full border border-gray-300 p-2 rounded-md">
        <span>开启翻译</span>
        <Switch checked={paramNode?.enableTranslate === 'Y'} onChange={(checked) => {
          onChange?.({
            ...paramNode,
            enableTranslate: checked ? 'Y' : 'N',
          });
        }} />
      </div>
    }
    <Input style={{ height: 40 }} placeholder="参数展示名称" value={paramNode?.name} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          name: e.target.value,
        });
      }}
    />
    <Input style={{ height: 40 }} placeholder="参数描述" value={paramNode?.description} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          description: e.target.value,
        });
      }}
    />
    <Input style={{ height: 40 }} placeholder="参数默认值" value={paramNode?.defaultValue} 
      onChange={(e) => {
        onChange?.({
          ...paramNode,
          defaultValue: e.target.value,
        });
      }}
    />
    <Button style={{ height: 40 }} type="text" onClick={onDelete?.bind(null, paramNode)}><DeleteOutlined /></Button>
  </div>

}

export interface ICheckPointSelector {
  checkpointNodes: ComfyNode[],
  nodeDefs: NodeDefs,
  value?: ComfyNode[];
  onChange?: (value: ComfyNode[]) => void;
  type?: 'checkpoint' | 'lora' | 'controlnet';
}

const paramKeyMap = {
  'checkpoint': ['ckpt_name', 'base_ckpt_name'],
  'lora': ['lora_name', 'lora_name_1', 'lora_name_2'],
  'controlnet': ['control_net_name'],
}

const CheckPointSelector: React.FC<ICheckPointSelector> = (props) => {
  const { checkpointNodes, nodeDefs, value = [], onChange, type='checkpoint' } = props;
  const paramKeys = paramKeyMap[type];

  const handleSelectChange = (index: number, paramKey: string, checkpointNode: ComfyNode, selectedValue) => {
    const newValue = [ ...value ];
    checkpointNode.inputs[paramKey] = selectedValue;
    newValue[index] = checkpointNode;
    onChange?.(newValue);
  };
  
  return <div className={style.modelItem}>
    {
      checkpointNodes.map((checkpointNode, index) => {
        const def: NodeDef = nodeDefs[checkpointNode.class_type];
        const paramKey = paramKeys.filter(key => def.input.required?.[key])[0];
        return <div key={index} className={style.modelSubItem}>
          {`${checkpointNode.nodeId}-${checkpointNode.class_type}`}
          <Select options={def.input.required?.[paramKey]?.[0]?.map(item => {
              return {
                label: item,
                value: item,
              }
            })} 
            style={{ width: 302 }} 
            placeholder="选择模型" 
            value={value?.[index]?.inputs?.[paramKey]} 
            onChange={handleSelectChange.bind(null, index, paramKey, checkpointNode)}
          />
        </div>
      })
    }
  </div>
};

export interface IParamSelectionCompProps {
  paramSelection: DefaultOptionType[];
  value?: Record<string, ParamNode>;
  onChange?: (value: Record<string, ParamNode>) => void;
  contentTpl?: Record<string, ComfyNode>;
  nodeDefs?: NodeDefs;
}

const ParamSelectionComp: React.FC<IParamSelectionCompProps> = (props: IParamSelectionCompProps) => {

  const { value = {}, onChange, paramSelection: propsParamSelection, contentTpl, nodeDefs } = props;
  const [paramNodes, setParamNodes] = useState<ParamNode[]>(Object.values(value));
  const [paramSelection, setParamSelection] = useState<DefaultOptionType[]>(propsParamSelection);
  const updateParamSelection = (propsParamSelection, newValue) => {
    setParamSelection(propsParamSelection.map(item => {
      item.children = item?.children?.map(paramNode => {
        let _key = `${paramNode.value}${item.value}`
        const disabled = !!newValue[_key];
        return {
          ...paramNode,
          disabled,
        }
      });
      return item;
    }));
  }
  useEffect(() => {
    if (!propsParamSelection?.length) return;
    if (value && Object.keys(value).length) {
      updateParamSelection(propsParamSelection, value);
    } else {
      setParamSelection(propsParamSelection);
    }
  }, [value, propsParamSelection]);

  useEffect(() => {
    if (value && Object.keys(value).length && !paramNodes.length) {
      setParamNodes(Object.values(value));
    }
  }, [paramNodes.length, value]);

  const appendParam = () => {
    const lastParamNode = paramNodes[paramNodes.length - 1];
    if (paramNodes.length && !lastParamNode?.nodeId) {
      message.error('请先填写上一个参数');
      return;
    }
    const newParamNodes = paramNodes.slice();
    newParamNodes.push({});
    setParamNodes(newParamNodes);
  }

  const onDelete = (paramNode) => {
    const newParamNodes = paramNodes.filter(item => item.key !== paramNode.key);
    setParamNodes(newParamNodes);
    delete value[paramNode.key];
    updateParamSelection(propsParamSelection, value);
  }

  if (!paramSelection.length) {
    return null;
  }

  if (!paramNodes.length) {
    return <Button onClick={appendParam} style={{ width: '100%' }} type="dashed">添加参数</Button>;
  }

  const buildParamNode = (paramNode: ParamNode) => {
    const nodeId = paramNode.nodeId;
    if (!nodeId) {
      console.error('nodeId is required, ', paramNode);
      return;
    }
    const classType = contentTpl?.[nodeId]?.class_type;
    if (!classType) {
      console.error('classType is required, ', paramNode);
      return;
    }
    const def = nodeDefs?.[classType];
    if (!def) {
      console.error('def is required, ', paramNode);
      return;
    }
    const { input } = def;
    const { required, optional, hidden } = input;
    if (!paramNode.paramKey) {
      console.error('paramNode.paramKey is required, ', paramNode);
      return;
    }
    if (required?.[paramNode.paramKey]) {
      const paramDef = required[paramNode.paramKey];
      parseParamDef(paramNode, paramDef);
    } else if (optional?.[paramNode.paramKey]) {
      const paramDef = optional[paramNode.paramKey];
      parseParamDef(paramNode, paramDef);
    } else if (hidden?.[paramNode.paramKey]) {
      const paramDef = hidden[paramNode.paramKey];
      parseParamDef(paramNode, paramDef);
    } else {
      console.error(`paramDef is required, nodeId: ${nodeId}, key: ${paramNode.key}, paramKey: ${paramNode.paramKey},  classType: ${classType}`);
    }
  }

  const handleParamChange = (paramNode: ParamNode, index: number) => {
    const newParamNodes = paramNodes.slice();
    newParamNodes[index] = paramNode;
    setParamNodes(newParamNodes);
    const newValue = {};
    newParamNodes.forEach(item => {
      item.key = `${item.paramKey}${item.nodeId}`;
      buildParamNode(item);
      if (item.key) {
        newValue[item.key] = item;
      }
    });
    onChange?.(newValue);
  }

  return <div className={style.paramGroup}>
    {
      paramNodes.map((paramNode, index) => {
        return <ParamItemComp
          key={index} 
          paramNode={paramNode}
          options={paramSelection} 
          onDelete={onDelete}
          onChange={(paramNode) => {
            handleParamChange(paramNode, index);
          }}
        />
      })
    }
    <Button onClick={appendParam} style={{ width: '100%', height: 40 }} type="dashed">添加参数</Button>
  </div>
}

export interface IOutputSelectionCompProps {
  outputNodes: ComfyNode[];
  nodeDefs: NodeDefs;
  value?: Record<string, OutputNode>;
  onChange?: (value: Record<string, OutputNode>) => void;
}

export interface IOutputItemCompProps {
  outputNode: OutputNode;
  nodeDefMap: Record<string, NodeDef>;
  outputSelection: DefaultOptionType[];
  value?: Record<string, OutputNode>;
  onChange?: (value: OutputNode) => void;
  onDelete?: (outputNode: OutputNode) => void;
}

const OutputItemComp: React.FC<IOutputItemCompProps> = (props: IOutputItemCompProps) => {
  const { outputNode, outputSelection, onChange, onDelete, nodeDefMap } = props;
  const onItemChange = (value) => {
    const [nodeId, paramKey] = value || [];
    const def = nodeDefMap?.[nodeId];
    const newOutputNode = {
      ...outputNode,
      nodeId,
      key: `${paramKey}${nodeId}`,
      paramKey: paramKey as string,
    };
    if (def) {
      const { input } = def;
      const { required, optional, hidden } = input;
      if (required?.[paramKey]) {
        const paramDef = required[paramKey];
        parseParamDef(newOutputNode, paramDef);
      } else if (optional?.[paramKey]) {
        const paramDef = optional[paramKey];
        parseParamDef(newOutputNode, paramDef);
      }
    }
    onChange?.(newOutputNode);
  }
  return <div className="flex flex-row gap-2">
    <Cascader
      defaultValue={[outputNode?.nodeId, outputNode?.paramKey]}
      style={{ height: 50 }} 
      options={outputSelection}
      onChange={onItemChange}
    />
    <div className="flex flex-row items-center justify-between gap-2 w-full border border-gray-300 p-2 rounded-md">
      <span>输出类型</span>
      <Select 
        style={{ width: 180 }} 
        options={outputTypeOptions} 
        value={outputNode?.paramType}
        onChange={(paramType) => {
          onChange?.({
            ...outputNode,
            paramType: paramType as string,
          });
        }}
      />
    </div>
    <Input 
      style={{ height: 50 }}
      placeholder="输出名称" 
      value={outputNode?.name}
      onChange={(e) => {
        onChange?.({
          ...outputNode,
          name: e.target.value,
        });
      }}
    />
    <Input 
      style={{ height: 50 }}
      placeholder="输出描述" 
      value={outputNode?.description}
      onChange={(e) => {
        onChange?.({
          ...outputNode,
          description: e.target.value,
        });
      }}
    />
    <Button style={{ height: 50 }} type="text" onClick={onDelete?.bind(null, outputNode)}><DeleteOutlined /></Button>
  </div>
}
const OutputSelectionComp: React.FC<IOutputSelectionCompProps> = (props: IOutputSelectionCompProps) => {
  const { outputNodes, value = {}, onChange, nodeDefs } = props;
  const [ outputItems, setOutputItems ] = useState<OutputNode[]>([]);
  const [ nodeDefMap, setNodeDefMap ] = useState<Record<string, NodeDef>>({});
  const [ outputSelection, setOutputSelection ] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    if (!value || !Object.keys(value).length) {
      return;
    }
    const outputItems = Object.values(value);
    setOutputItems(outputItems);
  }, [value]);

  useEffect(() => {
    if (!outputNodes || !outputNodes.length) {
      return;
    }
    const selections = []
    const selectedKeys = outputItems?.map(item => item.key);
    const nodeDefMap: Record<string, NodeDef> = {}
    outputNodes.forEach(node => {
      if (!node) return;
      const { nodeId, inputs, class_type } = node;
      const def = nodeDefs?.[class_type];
      if (def && nodeId) {
        nodeDefMap[nodeId] = def;
      }
      if (!inputs) return;
      const selection: DefaultOptionType = {
        label: `${nodeId} - ${node?._meta?.title || node.class_type}`,
        value: nodeId,
        children: [],
      };
      for (const key in inputs) {
        selection.children.push({
          label: key,
          value: key,
          disabled: selectedKeys?.includes?.(`${key}${nodeId}`),
        });
      }
      selections.push(selection);
    })
    setNodeDefMap(nodeDefMap);
    setOutputSelection(selections);
  }, [outputNodes, outputItems]);
  const onDelete = (outputNode: OutputNode) => {
    const newOutputNodes = outputItems.filter(item => item.nodeId !== outputNode.nodeId);
    setOutputItems(newOutputNodes);
    const newValue = {}
    newOutputNodes.forEach(node => {
      newValue[node.key] = node;
    })
    onChange?.(newValue);
  } 
  const appendOutput = () => {
    const newOutputNodes = outputItems.slice();
    newOutputNodes.push({});
    setOutputItems(newOutputNodes);
    const newValue = {}
    newOutputNodes.forEach(node => {
      newValue[node.key] = node;
    })
    onChange?.(newValue);
  }
  return <div className="flex flex-col gap-2">
    {
      outputItems.map((outputItem, index) => {
        return <OutputItemComp 
          onDelete={onDelete}
          outputSelection={outputSelection} 
          key={index} 
          outputNode={outputItem} 
          nodeDefMap={nodeDefMap}
          onChange={(outputNode) => {
            const newOutputNodes = outputItems.slice();
            newOutputNodes[index] = outputNode;
            setOutputItems(newOutputNodes);
            const newValue = {}
            newOutputNodes.forEach(node => {
              newValue[node.key] = node;
            })
            onChange?.(newValue);
          }}
        />
      })
    }
    <Button onClick={appendOutput} style={{ width: '100%', height: 50 }} type="dashed">添加输出</Button>
  </div>
}

const AppCreatorForm: React.FC<IAppCreatorFormProps> = (props: IAppCreatorFormProps) => {
  const { workflow, onChange, formRef } = props;
  const [ paramSelection, setParamSelection ] = useState<DefaultOptionType[]>([]);
  const [ checkpointNodes, setCheckpointNodes ] = useState<ComfyNode[]>([]);
  const [ loRANodes, setLoRANodes ] = useState<ComfyNode[]>([]);
  const [ controlnetNodes, setControlnetNodes ] = useState<ComfyNode[]>([]);
  const [ outputNodes, setOutputNodes ] = useState<ComfyNode[]>([]);
  const [ nodeDefs, setNodeDefs ] = useState<NodeDefs>({});
  const [form] = Form.useForm();
  const [formFields, setFormFields] = useState<FieldData[]>();
  const [isPrivate, setIsPrivate] = useState('N' as 'Y' | 'N');
  
  useEffect(() => {
    if (formRef) {
      formRef.current = form;
    }
  }, [formRef, form]);

  const clear = useCallback(() => {
    setParamSelection([]);
    setCheckpointNodes([]);
    setLoRANodes([]);
    setControlnetNodes([]);
    setOutputNodes([]);
    form.resetFields();
    setFormFields([]);
    onChange?.(null);
  }, [form]);

  useEffect(() => {
    const { name, code, description, cover, contentTpl, paramTpl, outputTpl, isPrivate } = workflow || {};
    if (!contentTpl) return;
    api.getNodeDefs().then((response) => {
      setNodeDefs(response);
      const nodeIds = Object.keys(contentTpl);
      const paramSelection: DefaultOptionType[] = [];
      const outputNodes: ComfyNode[] = [];
      const checkpointNodes: ComfyNode[] = [];
      const loRANodes: ComfyNode[] = [];
      nodeIds?.forEach((nodeId) => {
        const node: ComfyNode = contentTpl[nodeId];
        const { inputs, class_type } = node;
        const def: NodeDef = response[class_type];
        // console.log(`${nodeId} - ${node?._meta?.title || node.class_type}`, def);
        if (inputs && Object.keys(inputs).length && !(class_type in DEFAULT_OUTPUT_NODE_TYPE)) {
          const paramItem: DefaultOptionType = {
            value: nodeId,
            label: `${nodeId} - ${node?._meta?.title || node.class_type}`,
            children: []
          };
          for (const input in inputs) {
            switch (typeof(inputs[input])) {
              case 'string':
                paramItem?.children?.push({
                  label: input,
                  value: `${input}`,
                });
                break;
              case 'number':
                paramItem?.children?.push({
                  label: input,
                  value: `${input}`,
                });
                break;
              default:
                // console.log('Unknown input type: ', input, typeof(inputs[input]));
                break;
            }
          }
          paramSelection.push(paramItem);
          setParamSelection(paramSelection);
        }
        if (class_type in DEFAULT_CHECKPOINT_NODE_TYPE) {
          checkpointNodes.push({
            ...node,
            nodeId,
          });
          setCheckpointNodes(checkpointNodes);
        } 
        if (class_type in DEFAULT_LORA_NODE_TYPE) {
          loRANodes.push({
            ...node,
            nodeId,
          });
          setLoRANodes(loRANodes);
        }
        if (class_type in DEFAULT_CONTENT_NODE_TYPE) {
          controlnetNodes.push({
            ...node,
            nodeId,
          });
          setControlnetNodes(controlnetNodes);
        }

        if (def?.output_node) {
          outputNodes.push({
            ...node,
            nodeId,
          });
          setOutputNodes(outputNodes);
        }
      });
      const fields: FieldData[] = [
        {
          name: 'name',
          value: name,
        },
        {
          name: 'code',
          value: code,
        },
        {
          name: 'description',
          value: description,
        },
        {
          name: 'cover',
          value: cover,
        },
        {
          name: 'paramTpl',
          value: paramTpl,
        },
        {
          name: 'outputTpl',
          value: outputTpl,
        },
        {
          name: 'checkpoints',
          value: checkpointNodes,
        },
        {
          name: 'loras',
          value: loRANodes,
        },
        {
          name: 'controlnets',
          value: controlnetNodes,
        },
        {
          name: 'isPrivate',
          value: isPrivate,
        },
        {
          name: 'inviteCodes',
          value: JSON.parse(workflow?.inviteCodes || '[]'),
        }
      ];
      setIsPrivate(isPrivate === 'Y' ? 'Y' : 'N');
      setFormFields(fields);
    });
  }, [workflow]);

  const handleFieldsChange = async (changedFields) => {
    if (changedFields[0]?.name[0] === 'isPrivate') {
      setIsPrivate(changedFields[0].value);
    }
  }
  return <div>
    <Form 
      labelCol={{ span: 2 }}
      form={form}
      fields={formFields}
      onFieldsChange={handleFieldsChange}
    >
      <div className={style.workflowEditWrapper}>
        { checkpointNodes?.length || loRANodes?.length ? <div className={style.modelSection}>
          <div>
            <h2>模型配置</h2>
          </div>
          <div className={style.modelSelection}>
            { checkpointNodes?.length ? <div className={style.modelGroup}>
              <h4>CheckPoint</h4>
              <Form.Item name="checkpoints" label="" rules={[{ required: true }]}>
                <CheckPointSelector type='checkpoint' checkpointNodes={checkpointNodes} nodeDefs={nodeDefs} />
              </Form.Item>
            </div> : null }
            { loRANodes?.length ? <div className={style.modelGroup}>
              <h4>LoRA</h4>
              <Form.Item name="loras" label="" rules={[{ required: true }]}>
                <CheckPointSelector type='lora' checkpointNodes={loRANodes} nodeDefs={nodeDefs} />
              </Form.Item>
            </div>: null }
            { controlnetNodes?.length ? <div className={style.modelGroup}>
              <h4>ControlNet</h4>
              <Form.Item name="controlnets" label="" rules={[{ required: true }]}>
                <CheckPointSelector type='controlnet' checkpointNodes={controlnetNodes} nodeDefs={nodeDefs} />
              </Form.Item>
            </div>: null }
          </div>
        </div> : null }

        <div className={style.paramSection}>
          <div>
            <h2>参数配置</h2>
          </div>
          <div className={style.paramGroup}>
            <Form.Item name="paramTpl" label="" rules={[{ required: true }]}>
              <ParamSelectionComp contentTpl={workflow?.contentTpl} paramSelection={paramSelection} nodeDefs={nodeDefs} />
            </Form.Item>
          </div>
        </div>

        <div className={style.outputSection}>
          <div>
            <h2>结果配置</h2>
          </div>
          <div className={style.paramGroup}>
            <Form.Item name="outputTpl" label="" rules={[{ required: true }]}>
              <OutputSelectionComp outputNodes={outputNodes} nodeDefs={nodeDefs} />
            </Form.Item>
          </div>
        </div>
      </div>
    </Form>
  </div>
}

export default AppCreatorForm;
