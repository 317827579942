import { WorkflowItem } from "@/constants";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from './index.module.scss';
import { Button, Form, Input, Spin, message, Result, Modal, Tabs } from "antd";
import { Profile } from "@/models/common/user";
import { SdTaskResult } from "@/models/sd/SdFile";
import { getProfileData } from "@/services/user";
import { getWorkflowInviteCode, saveWorkflowInviteCode } from "@/utils";
import { runWorkflow } from "@/services/Workflow";
import { useGlobalStore } from "@/store";
import { useTranslation } from "react-i18next";
import ApiDocumentation from "./OpenAPI";
import ParamContainer from "./components/ParamRender";
import { NormalOutputRender } from "./components/OutputRender";


const WorkflowApp = (props) => {

  const { code } = useParams();
  const [ workflow, setWorkflow ] = useState<WorkflowItem>({} as WorkflowItem);
  const [globalLoading, setGlobalLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useGlobalStore((state) => state.currentUser);
  const [ noPermission, setNoPermission ] = useState(false);
  const [ needInviteCode, setNeedInviteCode ] = useState(false);
  const [ inviteCode, setInviteCode ] = useState('');
  const [currentWorks, setCurrentWorks] = useState<SdTaskResult>();
  const [latestGenerateTimestamp, setLatestGenerateTimestamp] = useState(0);
  const [t] = useTranslation();
  const navigate = useNavigate();
  
  const [isMobilePortrait, setIsMobilePortrait] = useState(false);
  const location = useLocation();

  const isNoHead = location.pathname.includes('/nohead/');

  useEffect(() => {
    function handleResize() {
      // 获取屏幕宽度和高度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight;

      // 判断是否是移动端并且处于竖屏状态
      setIsMobilePortrait(screenWidth < screenHeight && /Mobi|Android/i.test(navigator.userAgent));
    }
    // 添加窗口大小改变事件监听器
    window.addEventListener('resize', handleResize);

    // 初始状态下也触发一次handleResize以更新状态
    handleResize();

    // 清理函数，在组件卸载时移除事件监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    async function init() {
      const inviteCode = await getWorkflowInviteCode(code);
      if (inviteCode) {
        setInviteCode(inviteCode);
      }
    }
    init();
  }, []);

  const handleGenerate = async (param) => {
    try {
      const res = await runWorkflow(param, inviteCode);
      if (!res?.success || !res.data?.publicId) {
        messageApi.error(`${t('generateFailed')}: ${res?.message}`);
        return;
      }
      setLatestGenerateTimestamp(Date.now());
    } catch (e) {
      messageApi.error(`${t('generateFailed')}: ${e?.message}`);
    }
  }

  useEffect(() => {
    const fetchWorkflow = async () => {
      setGlobalLoading(true);
      const inviteCode = await getWorkflowInviteCode(code);
      const response = await fetch(`/open/api/v1/workflows/${code}?inviteCode=${inviteCode}`);
      const data = await response.json();
      if (!data.success) {
        message.error(`获取工作流失败: ${data.message}`);
        if (data.code === 403) {
          setNoPermission(true);
        } else if (data.code === 1004031) {
          setNeedInviteCode(true);
        } else if (data.code === 404) {
          navigate('/404');
        }
        return;
      }
      const workflow = data.data;
      const paramTpl = JSON.parse(workflow?.paramTpl || '{}');
      setWorkflow({
        ...workflow,
        contentTpl: JSON.parse(workflow?.contentTpl || '{}'),
        paramTpl,
        outputTpl: JSON.parse(workflow?.outputTpl || '{}'),
      });
      setGlobalLoading(false);
    };
    fetchWorkflow();
  }, [code, currentUser]);

  const handleSaveInviteCode = async (values) => {
    saveWorkflowInviteCode(code, values.inviteCode);
    window.location.reload();
  }

  if (noPermission || needInviteCode) {
    return <>
      <Modal title="请输入邀请码" open={needInviteCode} closable={false} closeIcon={null} footer={null}>
        <Form layout="inline" onFieldsChange={(changedFields) => {
          setInviteCode(changedFields[0].value);
        }} fields={[{
          name: 'inviteCode',
          value: inviteCode
        }]} onFinish={handleSaveInviteCode}>
          <div style={{ display: 'flex', width: '100%' }}>
            <Form.Item style={{ flex: 'auto' }} rules={[{ required: true }]} label="" name="inviteCode">
              <Input />
            </Form.Item>
            <div className={styles.submit}>
              <Button htmlType="submit" disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
            </div>
          </div>
        </Form>
      </Modal>
      <Result
        status="403"
        title={<div className={styles.errorTitle}>403 无权限访问</div>}
        subTitle={<div className={styles.errorSubTitle}>{`Sorry, you are not authorized to access workflow ${code}.`}</div>}
        extra={<Button type="primary" onClick={() => {
          navigate('/');
        }}>Back Home</Button>}
      />
    </>;
  }
  
  return (
    <><Spin spinning={globalLoading}><div className={styles.container} style={{
      height: isNoHead ? '100vh' : '100%',
    }}>
      {contextHolder}
      <div className={styles.content}>
        <Tabs type="card" tabPosition={isMobilePortrait ? "top" : "left"} defaultActiveKey="1">
          <Tabs.TabPane tab={t('workflow.app.playground')} key="1">
            <div className={`flex w-full ${isMobilePortrait ? 'flex-col px-4 gap-4' : ''}`}>
              <div className={`flex-1 ${isMobilePortrait ? 'w-full' : 'min-w-96 w-96 max-w-96'}`}>
                <ParamContainer 
                  code={code}
                  workflow={workflow} 
                  isMobilePortrait={isMobilePortrait}
                  onGenerate={handleGenerate}
                  currentWorks={currentWorks}
                  isNoHead={isNoHead}
                />
              </div>
              <div style={{ width: 0.5 }} className="mx-2 border-r border-dashed border-gray-300" />
              <div className="flex-auto">
                {
                  <NormalOutputRender
                    code={code}
                    workflow={workflow}
                    latestGenerateTimestamp={latestGenerateTimestamp}
                    isMobilePortrait={isMobilePortrait}
                    onSelect={(works) => {
                      setCurrentWorks(works);
                    }}
                    isNoHead={isNoHead}
                  />
                }
              </div>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("workflow.app.api")}>
            <div className={styles.apiArea}>
              <ApiDocumentation workflow={workflow} APITokenDescription={t('OpenAPI.APITokenDescription')} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
    </Spin>
    <Modal open={needInviteCode}>
      <Form fields={[{
        name: 'inviteCode',
        value: inviteCode
      }]} onFinish={handleSaveInviteCode}>
        <Form.Item rules={[{ required: true }]} label="请输入邀请码" name="inviteCode">
          <Input />
        </Form.Item>
        <div className={styles.submit}>
          <Button disabled={!inviteCode} className={styles.submitButton} type="primary">确定</Button>
        </div>
      </Form>
    </Modal>
    </>
  );
};

export default WorkflowApp;
