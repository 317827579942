import React from 'react';
import { RouteObject } from 'react-router-dom';
import Layout from './layout';
import Resample from './tool/resample';
import Rembg from './tool/rembg';
import Home from './home';
import DigitalTwinList from './digital-twin/list';
import DigitalTwinDetail from './digital-twin/detail';
import UserCenter from './user-center';
import Profile from './user-center/profile';
import Works from './user-center/works';
import Developer from './user-center/developer';
import Workflow from './workflow';
import WorkflowApp from './workflow/app';
import Member from './user-center/member';
import WorkflowHome from './workflow/home';
import ComfyUI from './comfyui';
import ComfyUIStudio from './comfyui/studio';
import Explore from './explore';
import ComfyUIPreview from './comfyui/preview';
import ComfyUIShare from './comfyui/share';
import AboutPage from './about';

const Loading = () => null;

const SdOnline = React.lazy(() => import('./sd-online'));
const SdFileManagement = React.lazy(() => import('./sdfile-management'));

const Page404 = React.lazy(() => import('./404'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <React.Suspense fallback={<Loading />}>
        <Layout />
      </React.Suspense>
    ),
    children: [
      {
        index: true,
        path: '/',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/about',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/concat',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Home />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/design',
        element: (
          <React.Suspense fallback={<Loading />}>
            <SdFileManagement />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/design/:publicId',
        element: (
          <React.Suspense fallback={<Loading />}>
            <SdOnline />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/comfyui',
        element: (
          <React.Suspense fallback={<Loading />}>
            <ComfyUI />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/comfyui/:publicId',
        element: (
          <React.Suspense fallback={<Loading />}>
            <ComfyUI />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/comfyui/share/:publicId',
        element: (
          <React.Suspense fallback={<Loading />}>
            <ComfyUIShare />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/explore',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Explore />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/digital-twin',
        element: (
          <React.Suspense fallback={<Loading />}>
            <DigitalTwinList />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/digital-twin/:publicId',
        element: (
          <React.Suspense fallback={<Loading />}>
            <DigitalTwinDetail />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/tool-resample',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Resample />
          </React.Suspense>
        ),
      },
      {
        index: true,
        path: '/tool-rembg',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Rembg />
          </React.Suspense>
        ),
      },
      // {
      //   path: '/models',
      //   element: (
      //     <React.Suspense fallback={<Loading />}>
      //       <ModelManagement />
      //     </React.Suspense>
      //   ),
      //   children: [],
      // }
      {
        path: '/workflow',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Workflow />
          </React.Suspense>
        ),
        children: [
          {
            path: '',
            element: (
              <React.Suspense fallback={<Loading />}>
                <WorkflowHome />
              </React.Suspense>
            ),
            children: [],
          },
          {
            path: 'app/:publicId',
            element: (
              <React.Suspense fallback={<Loading />}>
                <WorkflowHome />
              </React.Suspense>
            ),
            children: [],
          },
          {
            path: ':code',
            element: (
              <React.Suspense fallback={<Loading />}>
                <WorkflowApp />
              </React.Suspense>
            ),
            children: [],
          },
        ],
      },
      {
        path: '/space',
        element: (
          <React.Suspense fallback={<Loading />}>
            <UserCenter />
          </React.Suspense>
        ),
        children: [
          {
            path: ':publicId',
            element: (
              <React.Suspense fallback={<Loading />}>
                <Works />
              </React.Suspense>
            ),
            children: [],
          },
          {
            path: 'member',
            element: (
              <React.Suspense fallback={<Loading />}>
                <Member />
              </React.Suspense>
            ),
            children: [],
          },
          {
            path: 'profile',
            element: (
              <React.Suspense fallback={<Loading />}>
                <Profile />
              </React.Suspense>
            ),
            children: [],
          },
          {
            path: 'developer',
            element: (
              <React.Suspense fallback={<Loading />}>
                <Developer />
              </React.Suspense>
            ),
            children: [],
          }
        ],
      },
      {
        path: '/*',
        element: (
          <React.Suspense fallback={<Loading />}>
            <Page404 />
          </React.Suspense>
        ),
        children: [],
      },
    ],
  },
  {
    index: true,
    path: '/comfyui/workflow/:publicId',
    element: (
      <React.Suspense fallback={<Loading />}>
        <ComfyUIStudio />
      </React.Suspense>
    ),
  },
  {
    path: '/comfyui/preview',
    element: (
      <React.Suspense fallback={<Loading />}>
        <ComfyUIPreview />
      </React.Suspense>
    ),
    children: [],
  },
  {
    path: '/nohead/workflow/:code',
    element: (
      <React.Suspense fallback={<Loading />}>
        <WorkflowApp />
      </React.Suspense>
    ),
    children: [],
  },
  {
    path: '/*',
    element: (
      <React.Suspense fallback={<Loading />}>
        <Page404 />
      </React.Suspense>
    ),
    children: [],
  },
];

export default routes;
